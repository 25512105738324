import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Link from '@material-ui/core/Link';
import { getLink } from '../utils/getLink';

const useStyles = makeStyles((theme) => ({
  cardContainer: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    maxWidth: '500px',
    backgroundColor: '#fff',
    borderRadius: '10px',
    overflow: 'hidden',
    color: 'black',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
    textAlign: 'left',
    transition: 'transform 0.3s ease-out',
    '&:hover': {
      transform: 'scale(1.05)',
    },
  },
  cardImage: {
    width: '100%',
    height: '200px',
    objectFit: 'cover',
    backgroundColor: '#DDD',
  },
  cardContent: {
    padding: '20px',
    flex: '1', // Ensure the content takes up the remaining space
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start', // Ensure content is spaced evenly
  },
  cardTitle: {
    fontSize: '1.5rem',
    margin: '0 0 5px 0',
  },
  expiryDate: {
    color: '#222',
    fontSize: '0.8rem',
    marginBottom: '10px',
  },
  cardDescription: {
    fontSize: '1rem',
    color: 'black',
    textAlign: 'left',
  },
  learnMoreLink: {
    color: '#008cff',
    textDecoration: 'none',
    fontWeight: 'bold',
    cursor: 'pointer',
  },
  readMoreLess: {
    color: theme.palette.primary.main,
    cursor: 'pointer',
    marginTop: '16px',
    fontWeight: '700',
  },
}));

const formatDate = (dateString) => {
  const date = new Date(dateString);
  return date.toLocaleDateString('en-GB', {
    day: '2-digit',
    month: 'long',
    year: 'numeric',
  });
};

const ReadMore = ({ children }) => {
  const classes = useStyles();
  const [isReadMore, setIsReadMore] = useState(true);

  const toggleReadMore = () => {
    setIsReadMore(!isReadMore);
  };
  const content = typeof children === 'string' ? children : String(children);

  return (
    <div>
      <div
        className={isReadMore ? classes.cardDescription : ''}
        dangerouslySetInnerHTML={{
          __html: isReadMore ? `${content.slice(0, 80)}...` : content,
        }}
      ></div>
      <Link
        component="button"
        onClick={toggleReadMore}
        className={classes.readMoreLess}
      >
        {isReadMore ? 'Read More' : 'Read Less'}
      </Link>
    </div>
  );
};

// CardComponent that accepts image, title, and description as props
const CardComponent = ({ data, style }) => {
  const classes = useStyles();
  const currentDate = new Date();
  console.log(currentDate);
  if (new Date(data.exprie) < currentDate) {
    return null;
  }

  return (
    <div className={classes.cardContainer} style={style}>
      <a
        href={data.url || '/opportunities/offcampus'}
        target="_blank"
        rel="noopener noreferrer"
        className={classes.cardImage}
      >
        <img
          src={getLink(data.image)}
          alt={data.title}
          className={classes.cardImage}
        />
      </a>
      <div className={classes.cardContent}>
        <div>
          <h2 className={classes.cardTitle}>{data.title}</h2>
          {data.exprie ? (
            <p className={classes.expiryDate}>
              Last Date: {formatDate(data.exprie)}
            </p>
          ) : (
            <div></div>
          )}
        </div>
        <ReadMore>{data.description}</ReadMore>
      </div>
    </div>
  );
};

export default CardComponent;

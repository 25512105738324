import React, { useState, useEffect } from 'react';
import Container from '@material-ui/core/Container';
import instance from '../api/axios';
import Loading from '../components/Loading';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import BusinessIcon from '@material-ui/icons/Business';
import FadeInWhenVisible from '../components/Animation/FadeIn';
import FadeUpBigDataWhenVisible from '../components/Animation/FadeUpBigData';
import CardComponent from '../components/ReadMore';
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    display: 'flex',
    marginBottom: '3rem',
    [theme.breakpoints.down(460)]: {
      padding: 2,
    },
  },
  paper: {
    padding: theme.spacing(2),
    borderRadius: '1rem',
    [theme.breakpoints.up(460)]: {
      paddingInline: 40,
    },
    width: 'auto',
    color: 'rgb(0,0,0)',
    transition: 'transform 0.3s',
    '&:hover': {
      transform: 'scale(1.00)',
    },
  },
  text: {
    color: 'rgb(0,0,0)',
    fontsize: '1rem',
  },
  expiryDate: {
    color: '#222',
    fontSize: '0.8rem',
    marginTop: '10px',
  },
  heading: {
    fontSize: '1.8rem',
    color: '#fff',
    backgroundColor: '#012970',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '6rem 0rem 1rem 0rem',
    padding: '1rem',
  },
}));
const OffCampus = () => {
  const classes = useStyles();
  const [Opportunities, setOpportunities] = useState([]);
  const [loading, setLoding] = useState(true);

  // const sampleOpportunities = [
  //   {
  //     title: 'Software Engineer Intern',
  //     exprie: '2025-12-31T23:59:59Z',
  //     description:
  //       'Join our team as a Software Engineer Intern and work on exciting projects.',
  //     image:
  //       'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTnvIzScvGWY_FB0qJvU9SSgF0UneXQfZ9ZzA&s',
  //   },
  //   {
  //     title: 'Data Analyst',
  //     exprie: '2023-11-30T23:59:59Z',
  //     description:
  //       'We are looking for a Data Analyst to help us make data-driven decisions.',
  //     image:
  //       'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQwbT-3hoYZC_OkN1-ywSVywRFScyC3UlrXohBTHyAAJpvdRQ243fTrZG5II7Xn8ZwiIcc',
  //   },
  //   {
  //     title: 'Marketing Specialist',
  //     exprie: '2023-10-15T23:59:59Z',
  //     description:
  //       'As a Marketing Specialist, you will be responsible for developing marketing strategies. As a Marketing Specialist, you will be responsible for developing marketing strategies.',
  //     image:
  //       'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ58uMqbGLlXqJPX7tVedqWGt67JH9aaEzN0g&s',
  //   },
  //   {
  //     title: 'Marketing Specialist',
  //     exprie: '2023-10-15T23:59:59Z',
  //     description:
  //       'As a Marketing Specialist, you will be responsible for developing marketing strategies. As a Marketing Specialist, you will be responsible for developing marketing strategies.',
  //     image:
  //       'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ58uMqbGLlXqJPX7tVedqWGt67JH9aaEzN0g&s',
  //   },
  // ];

  useEffect(() => {
    instance
      .get('company/get_offcampus/')
      .then((res) => {
        setOpportunities(res.data);
      })
      .then(() => setLoding(false))
      .catch((error) => console.log(error));

    // testing component
    // setOpportunities(sampleOpportunities);
    // setLoding(false);
  }, []);

  return (
    <div style={{ height: 'auto', width: '100%' }}>
      {loading ? (
        <Loading />
      ) : (
        <>
          <Container maxWidth="lg" className={classes.root}>
            <Grid container spacing={3} justifyContent="space-around">
              <Grid style={{ marginTop: '0.5rem' }} item xs={12}>
                <FadeInWhenVisible>
                  <Paper
                    className={classes.heading}
                    style={{ background: '#012970', color: '#fff' }}
                    elevation={2}
                  >
                    <BusinessIcon
                      style={{
                        margin: '0 0.8rem',
                        fontSize: '2.5rem',
                        paddingTop: '0rem',
                      }}
                    />
                    Off Campus Opportunities
                  </Paper>
                </FadeInWhenVisible>
              </Grid>
              {Opportunities ? (
                Opportunities.map((opportunity, index) => (
                  <Grid item xs={12} lg={4} md={6} key={index}>
                    <FadeUpBigDataWhenVisible>
                      {/* <h2 style={{ fontSize: '1.4rem' }}>
                          {opportunity.title}
                        </h2> */}

                      {/* <ReadMore style={{ marginTop: '12px', width: '100%' }}>
                          {opportunity.description}
                        </ReadMore> */}
                      <CardComponent
                        // className={classes.paper}
                        data={opportunity}
                        style={{ margin: '12px auto', width: '100%' }}
                      />
                    </FadeUpBigDataWhenVisible>
                  </Grid>
                ))
              ) : (
                <Grid item xs={12}>
                  <FadeUpBigDataWhenVisible>
                    <Paper className={classes.paper}>
                      <p>Coming soon...</p>
                    </Paper>
                  </FadeUpBigDataWhenVisible>
                </Grid>
              )}
            </Grid>
          </Container>
        </>
      )}
    </div>
  );
};

export default OffCampus;

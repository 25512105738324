import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import styles from './HeroSection.module.css';
import FadeInWhenVisible from '../Animation/FadeIn';
import FadeUpWhenVisible from '../Animation/FadeUp';
import logo from '../../assets/white.png';
function HeroSection() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className={styles.hero_container}>
      <div>
        <img src={logo} alt="logo" />
        <h1>
          <FadeUpWhenVisible>Training & Placement Office</FadeUpWhenVisible>
        </h1>
      </div>
      <div
        style={{
          height: '1px',
          background:
            'linear-gradient(to right, transparent, rgb(200, 200, 200), transparent)',
          margin: '4px 2px',
          width: '40%',
        }}
      >
        <FadeInWhenVisible></FadeInWhenVisible>
      </div>

      <span style={{ marginTop: '10px' }}>
        <FadeUpWhenVisible>
          Indian Institute of Technology Jodhpur
        </FadeUpWhenVisible>
      </span>
      <div className={styles.home_section_btx}>
        <Link to="/recruiter-login">Recruiter’s Corner</Link>
        <Link to="/student-login">Student’s Corner</Link>
      </div>
    </div>
  );
}

export default HeroSection;
